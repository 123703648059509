<template>
  <div class="affnews-wrapper mx-auto">
    <div class="top-content">
      <!--      <div class="top-left-corner-label"></div>-->
      <v-row no-gutters class="top-left-label">ভাগ্য এ্যাফিলিয়েট প্রোগ্রাম</v-row>
      <v-row no-gutters class="top-full-row-label ">
        <div class="full-width">
          টাকা আয় করুন
        </div>
        <div class="full-width">
          ভাগ্যা - এর সাথে!!
        </div>
      </v-row>
      <div class="imgContains">
        <v-img class="ladies" contain src="/static/image/affiliate/ladies.png" :aspect-ratio="540 / 360"></v-img>
        <img class="coins" src="/static/image/affiliate/affNewsCoins.png" />
        <img class="backLight" src="/static/image/affiliate/backLight.png" />
      </div>
      <!--      <img src="/static/image/affiliate/ladies.png" />-->
      <!--      <v-row class="top-full-row-label "></v-row>-->
    </div>
    <div class="bottom-content">
          <v-row>
            <v-col class="d-flex justify-center">
            <v-col cols="8" >
              <v-btn @click="gotoForm" class="full-width chip-text">
                <span class="contact-finger-icon">👇</span>
                রেজিস্ট্রেশন করতে ক্লিক করুন
              </v-btn>
            </v-col>
            </v-col>
          </v-row>
      <div class="tnc">
        <h3 style="color: #4D5A30;">আমরা এ্যাফিলিয়েট প্রোগ্রামে
          কিভাবে কাজ করি?</h3>
        <p class="tnc1 pt-3">
          আপনি আমাদের সাথে কাজ করলে ২০ জনের উপরে এ্যাক্টিভ প্লেয়ারে পাবেন ৩৫% কমিশন এবং ২০ এর নীচে এ্যাক্টিভ প্লেয়ারে পাবেন ২০% কমিশন।প্লেয়ারের লস থেকে বোনাস এবং আমাদের ২০% অপারেশন ফি বাদ যাবার পরে সেটির উপর আপনার কমিশন হিসাব করা হবে।        </p>
        <p class="tnc2">
          প্রতি মাসের ২-৩ তারিখে উইথড্র রিকুয়েস্ট দিতে পারবেন এবং ৫তারিখের মধ্যেই আপনার কমিশনের টাকা বুঝে পাবেন। মিনিমাম ৮০০ টাকা আর ৫টি এ্যাক্টিভ প্লেয়ার থাকলেই আপনি উইথড্র রিকুয়েস্ট দিতে পারবেন        </p>
      </div>
      <div class="affNews-info">
        <p>
          রআমাদের কাস্টোমার সাপোর্ট টাইম সকাল ১০টা থেকে সন্ধ্যা ৭টা (রবিবার বন্ধ)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {locale, uiHelper} from "@/util";

export default {
  name: 'promoNews',
  created() {
    this.metaTag()
  },
  methods:{
    gotoForm(){
      window.open('https://forms.gle/ifropRSBZNmpq97H8');
    },
    gotoWhatsapp(){
      window.open('http://wa.me/+60147746114');
    },
    metaTag() {
      uiHelper.setTitle(`${locale.getMessage('meta.promoNews_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.promoNews_description')}`)
    },
  }
}
</script>

<style lang="scss">
//@font-face {
//  font-family: 'Li-Ador-Noirrit-A';
//  src: url('/static/font/Li_Ador_Noirrit.ttf');
//}
@font-face {
  font-family: 'Li-Ador-Noirrit-2';
  src: url('/static/font/Li_Ador_Noirrit_A_V2.ttf');
}
.chip-text {
  font-family: 'Li-Ador-Noirrit-2';
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  height: 55px !important;
  color: #FFFFFF !important;
  background: linear-gradient(92.5deg, #517500 1.84%, #80A923 96.78%) !important;
  border-radius: 30px !important;
  .contact-finger-icon {
    transform: rotate(-120deg);
    font-size: 1.5rem;
  }
}
.bottom-content {
  padding: 3% 3% 3% 3%;
  max-width: 599px;
  .contact-card {
    background: rgba(106, 145, 18, 0.5);
    border-radius: 15px;
    height: 95px;
  }
  .contact-card-title {
    font-family: 'Li-Ador-Noirrit-2';
    font-weight: 700;
    font-size: 1rem;
    line-height: 26px;
    padding: 10px;
  }
  .tnc {
    padding: 3% 3% 3% 5%;
    font-weight: 700;
    font-size: 25px;
  }
  .tnc1 {
    font-weight: 400;
    font-size: 16px;
  }
  .tnc2 {
    font-weight: 600;
    font-size: 18px;
  }
  .affNews-info {
    padding: 0% 3% 3% 3%;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #4D5A30;
    text-align: center;
  }
}
.affnews-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fff;
  max-width: 599px;
}
.imgContains {
  position: relative;
}
.ladies {
  z-index: 2;
}
.coins {
  position: absolute;
  width: 100%;
  bottom: -15%;
}
.backLight {
  position: absolute;
  width: 100%;
  bottom: -208%;
  opacity: 0.5;
}
.top-content {
  background: linear-gradient(93.88deg, #80a923 -5.4%, #517500 143.78%);
  border-bottom-right-radius: 40px;
  height: 100%;
  position: relative;
  max-width: 599px;
}
.top-left-label {
  padding: 3% 3%;
  color: #bdff00;
}
.top-full-row-label {
  justify-content: left;
  padding: 0% 3%;
  color: #ffffff;
  font-family: 'Li-Ador-Noirrit-2';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (min-width: 300px) {
  .chip-text {
    font-size: 10px !important;
  }
}

@media (min-width: 380px) {
  .chip-text {
    font-size: 12px !important;
  }
}

@media (min-width: 500px) {
  .chip-text {
    font-size: 14px !important;
  }
}

@media (min-width: 600px) {
  .chip-text {
    font-size: 16px !important;
  }
}

@media (max-width: 400px) {
  .chip-text {
    padding: 8px 0px;
  }
  .bottom-content .contact-card .chip-text .contact-finger-icon{
    font-size: 1.3rem;
  }
  .contact-finger-icon {
    font-size: 1.3rem;
  }
  .messenger-icon {
    width: 25px;
  }
}
</style>
